import {
	Box,
	Flex,
	Progress,
	VStack,
	Button,
	Text,
	Heading,
	Slide,
	Skeleton,
} from "@chakra-ui/react";
import {
	useState,
	useEffect,
	useRef,
	useCallback,
	// lazy,
	Suspense,
	useContext,
} from "react";
import { useSwipeable } from "react-swipeable";
import { keyframes } from "@emotion/react";
import { AppContext } from "../../context/AppContext";
import { useNavigate } from "react-router-dom";

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;


const AUTO_PLAY_DURATION = 5000; // 5 seconds for each story

// const LocationDetail = lazy(() => import("./LocationDetail"));

const CollectionStories: React.FC = () => {
	const navigate = useNavigate();
	const [currentPlaceIndex, setCurrentPlaceIndex] = useState<number>(0); // Track the current place
	const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0); // Track the current video of a place
	const [progress, setProgress] = useState(0);
	const [isPlaceOpen, setIsPlaceOpen] = useState(false);

	const videoRef = useRef<HTMLVideoElement | null>(null);
	const context: any = useContext(AppContext);
	const { stories, storyList } = context;

	const onStart = useCallback(() => {
		console.log(storyList);
		const story = storyList.find(
			(story: { title: any }) => story.title === stories
		);
		console.log(story?.id);
		setCurrentPlaceIndex(story ? story.id - 1 : 0);
	}, [stories, storyList]);

	useEffect(() => {
		onStart();
	}, [onStart]);

	const currentPlace = storyList?.[currentPlaceIndex] || {}; // Ensure currentPlace has a default value
	const currentVideo = currentPlace.videoUrl
		? currentPlace.videoUrl[currentVideoIndex]
		: "";

	useEffect(() => {
		const videoPreload = new Image();
		videoPreload.src =
			currentPlace?.videoUrl?.[currentVideoIndex + 1] ||
			storyList?.[0]?.videoUrl?.[0];
	}, [currentVideoIndex, currentPlace?.videoUrl, storyList]);

	const goToNextPlace = useCallback(() => {
		setCurrentPlaceIndex((prevIndex) => {
			const nextPlaceIndex =
				prevIndex < storyList.length - 1 ? prevIndex + 1 : 0;
			setCurrentVideoIndex(0); // Reset video index when moving to the next place
			setProgress(0);
			return nextPlaceIndex;
		});
	}, [storyList.length]);

	const goToNextVideo = useCallback(() => {
		if (currentVideoIndex < currentPlace.videoUrl?.length - 1) {
			setCurrentVideoIndex(currentVideoIndex + 1);
			setProgress(0);
		} else {
			goToNextPlace();
		}
	}, [currentVideoIndex, currentPlace.videoUrl?.length, goToNextPlace]);

	const goToPrevPlace = useCallback(() => {
		setCurrentPlaceIndex((prevIndex) => {
			const prevPlaceIndex =
				prevIndex > 0 ? prevIndex - 1 : storyList.length - 1;
			setCurrentVideoIndex(0); // Reset video index when moving to the previous place
			setProgress(0);
			return prevPlaceIndex;
		});
	}, [storyList.length]);

	const handleTap = useCallback(() => {
		goToNextVideo(); // Go to the next video or place on tap
	}, [goToNextVideo]);

	const handleSwipeLeft = () => {
		goToPrevPlace(); // Swipe left for previous place story
	};

	const handleSwipeRight = () => {
		goToNextPlace(); // Swipe right for next place story
	};

	const handleSwipeUp = () => {
		setIsPlaceOpen(true);
	};

	const handlers = useSwipeable({
		onSwipedLeft: handleSwipeRight,
		onSwipedRight: handleSwipeLeft,
		onSwipedUp: handleSwipeUp,
		onTap: handleTap,
	});

	// Handle video load
	useEffect(() => {
		const video = videoRef.current;
		if (video) {
			const handleCanPlay = () => { };
			video.addEventListener("canplay", handleCanPlay as EventListener);
			return () => {
				video.removeEventListener("canplay", handleCanPlay as EventListener);
			};
		}
	}, [currentPlaceIndex, currentVideoIndex]);

	// Auto-play next video
	useEffect(() => {
		const interval = AUTO_PLAY_DURATION / 100;
		const progressTimer = setInterval(() => {
			setProgress((prevProgress) => {
				if (prevProgress >= 100) {
					clearInterval(progressTimer);
					goToNextVideo(); // Automatically switch to the next video or place
					return 0;
				}
				return prevProgress + 1;
			});
		}, interval);

		return () => {
			clearInterval(progressTimer);
		};
	}, [currentVideoIndex, goToNextVideo]);

	return (
		<Box
			id="story-container"
			position="relative"
			width="100vw"
			height="100vh"
			bg="black"
			overflow="hidden"
			display="flex"
			justifyContent="center"
			alignItems="center"
			{...handlers}
		>
			{/* Loading placeholder if storyList is empty */}
			{!storyList || storyList.length === 0 ? (
				<Text>Loading stories...</Text>
			) : (
				<>
					{/* Back Button */}
					<Box position="absolute" top="10vh" width="100%" px={3} zIndex={5}>
						<Button
							bg="inherit"
							variant="link"
							onClick={() => navigate("/home/modal")}
						>
							<img src="/icons/Back_Icon_White.svg" alt="back button" />
						</Button>
					</Box>
					<Box
						position="absolute"
						top="0"
						left="0"
						width="100vw"
						height="50vh"
						zIndex={2}
						bg="linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 100%)"
					/>
					<Box
						position="absolute"
						bottom="0"
						left="0"
						zIndex={2}
						width="100vw"
						height="45vh"
						bg="linear-gradient(180deg, rgba(0, 0, 0, 0) 37.93%, rgba(0, 0, 0, 0.99) 100%)"
					/>
					{/* Progress Bars for current videos */}
					<Flex position="absolute" top="5vh" width="100%" px={5} gap={2} zIndex={5}>
						{currentPlace?.videoUrl?.map((_: any, index: number) => (
							<Progress
								key={index}
								value={
									index === currentVideoIndex
										? progress
										: index < currentVideoIndex
											? 100
											: 0
								}
								height="2px"
								flex={1}
								colorScheme="gray"
								bg="gray.600"
							/>
						))}
					</Flex>

					{/* Video Container */}
					<Box position="absolute" width="100vw" height="100vh">
						<Box
							as="video"
							ref={videoRef}
							src={currentVideo}
							autoPlay
							muted
							loop
							preload="auto"
							onEnded={goToNextVideo}
							height="100%"
							width="100%"
							objectFit="cover"
							zIndex={1}
						/>

						{/* Story Text and Details */}
						<Box px={4} position='fixed' bottom='15vh' w='100%'>
							<Box position='relative' zIndex={5}>
								<Heading
									fontFamily='Poppins'
									fontWeight={700}
									fontSize='53px'
									ml={-1}
									lineHeight='43px'
									bgGradient='linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(192, 192, 192, 0.93) 95.69%)'
									bgClip='text'>
									{currentPlace.title}
								</Heading>
								<Text
									color='white'
									mt='16px'
									fontFamily='Poppins'
									fontSize='13px'
									fontWeight='500'
									lineHeight='16.95px'
									letterSpacing='0.30000001192092896px'
									textAlign='left'>
									{currentPlace.subtitle}
								</Text>
							</Box>
							<Text
								color='white'
								mt='16px'
								fontSize='14px'
								fontWeight='400'
								lineHeight='19px'
								letterSpacing='-0.699999988079071px'
								textAlign='left'
								bgGradient={
									'linear-gradient(180deg, #FFFFFF 14.74%, rgba(15, 15, 15, 0) 119.41%)'
								}
								bgClip={'Text'}
								zIndex={1}>
								{currentPlace.details}
							</Text>
						</Box>
					</Box>

					{/* Scroll Up Button */}
					<Flex
						position='fixed'
						bottom='20px'
						width='100%'
						justifyContent='center'
						alignItems='center'
						zIndex={4}>
						<VStack spacing={2}>
							<Button
								bg='inherit'
								variant='link'
								onClick={handleSwipeUp}
								animation={`${moveUpDown} 1s infinite`}
								zIndex={4}>
								<img src='/icons/ArrowUp.svg' alt='swipe up' />
							</Button>
							<Text
								fontSize='13px'
								color='#9D9D9D'
								mb='17px'
								fontWeight='500'
								zIndex={4}>
								SCROLL UP FOR MORE
							</Text>
						</VStack>
					</Flex>

					{/* Full Place Details (Swipe Up) */}
					<Slide direction='bottom' in={isPlaceOpen} style={{ zIndex: 10 }}>
						<Box p={4} bg='#000000' h='100vh' overflowY='auto'>
							<Suspense fallback={<Skeleton height='100vh' width='100vw' />}>
								{/* <LocationDetail handleBackButton={() => setIsPlaceOpen(false)} /> */}
							</Suspense>
						</Box>
					</Slide>
				</>
			)
			}
		</Box >
	);
};

export default CollectionStories;
