import {
    Box,
    Flex,
    Image,
    Progress,
    VStack,
    Text,
    Heading,
    Button,
    Slide,
    Skeleton
} from "@chakra-ui/react";
import { keyframes } from '@emotion/react';
import { useState, useEffect, lazy, Suspense, useContext, useCallback, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

// Lazy load the PlaceDetail component
const AUTO_PLAY_DURATION = 5000; // 5 seconds for each story

const PlaceDetail = lazy(() => import("./PlaceDetail"));

interface Story {
    id: number;
    title: string;
    images: string[];
    subtitle: string;
    details: string;
}



// Keyframe animation for the scroll-up button
const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const Stories: React.FC = () => {
    const navigate = useNavigate();

    const context: any = useContext(AppContext);
    const { storyList, stories } = context;
    const storiesList: Story[] = useMemo(() => {
        return storyList.length
            ? storyList
            : [
                {
                    id: 1,
                    title: "Bangkok Bold kitchen",
                    images: [
                        "/images/dish1.jpg",
                        "/images/dish1.jpg",
                        "/images/dish1.jpg"
                    ],
                    subtitle: "LGF, Central Embassy, 1031 Phloen Chit Road, Lumphini, Pathum Wan, Bangkok, 10330",
                    details: "Word-of-mouth inspired Chef Nhoi to take the leap from cooking studio in the old town to opening a restaurant in the city centre. Her aim is to preserve the heritage of Thai taste that’s ‘basic and bold’ with creative twists."
                },
                {
                    id: 2,
                    title: "Haoma Bangkok",
                    images: [
                        "/images/dish3.jpg",
                        "/images/dish3.jpg",
                        "/images/dish3.jpg"
                    ],
                    subtitle: "Discover Haoma · Reserve Your Table. The Chef · Tasting Menus - 15 courses",
                    details: "Embark on a Neo-Indian fine dining journey in Bangkok, led by our dedicated restaurant manager. Every dish reflects sustainability."
                }
            ];
    }, [storyList]);

    const [currentStoryIndex, setCurrentStoryIndex] = useState<number>(0);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const onStart = useCallback(() => {
        const story = storiesList.find(story => story.title === stories);
        console.log(story?.id)
        setCurrentStoryIndex(story ? story.id - 1 : 0);
    }, [stories, storiesList]);

    const [isPlaceOpen, setIsPlaceOpen] = useState<boolean>(false);
    const currentStory = storiesList[currentStoryIndex];
    const currentImage = currentStory.images[currentImageIndex];

    const goToNextStory = useCallback(() => {
        setCurrentStoryIndex((prevIndex) => (prevIndex < storiesList.length - 1 ? prevIndex + 1 : 0));
        setCurrentImageIndex(0); // Reset to the first image of the next story
        setProgress(0); // Reset progress when moving to the next story
    }, [storiesList.length]);

    const goToNextImage = useCallback(() => {
        if (currentImageIndex < currentStory.images.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1); // Go to the next image
            setProgress(0); // Reset progress for the next image
        } else {
            goToNextStory(); // Go to the next story if all images are shown
        }
    }, [currentImageIndex, currentStory.images.length, goToNextStory]);



    useEffect(() => {
        const interval = AUTO_PLAY_DURATION / 100;
        const progressTimer = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(progressTimer);
                    goToNextImage();
                    return 0;
                }
                return prevProgress + 1;
            });
        }, interval);

        return () => {
            clearInterval(progressTimer);
        };
    }, [currentImageIndex, currentStoryIndex, goToNextImage]);

    const handleSwipeUp = () => {
        setIsPlaceOpen(true);  // Show place details
    };
    const goToPreviousStory = () => {
        setCurrentStoryIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : storiesList.length - 1));
        setCurrentImageIndex(0); // Reset image index on story change
        setProgress(0);
    };
    const handleSwipeDown = () => {
        setIsPlaceOpen(false); // Hide place details
    };

    const handlers = useSwipeable({
        onSwipedLeft: goToNextStory,
        onSwipedRight: goToPreviousStory,
        onSwipedUp: handleSwipeUp,
        onSwipedDown: handleSwipeDown,
    });

    const handleTap = () => {
        goToNextImage(); // Tap to go to next image
    };
    useEffect(() => {
        onStart();
    }, [onStart]);

    return (
        <Box
            position="relative"
            width="100vw"
            height="100vh"
            bg="black"
            overflow="hidden"
            display="flex"
            justifyContent="center"
            alignItems="center"
            {...handlers}
            onClick={handleTap}
        >
            {/* Background gradients */}
            <Box position="fixed" top="0" left="0" width="100vw" height="50vh" bg="linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 100%)" zIndex={2} />
            <Box position="fixed" bottom="0" left="0" width="100vw" height="45vh" bg="linear-gradient(0deg, #111417 7.37%, rgba(7, 9, 11, 0) 100%)" zIndex={2} />

            {/* Progress bars at the top */}
            <Flex position="absolute" top="10px" width="100%" px={5} gap={2} zIndex={5} mt="30px">
                {currentStory.images.map((_, index) => (
                    <Progress
                        key={index}
                        value={index === currentImageIndex ? progress : index < currentImageIndex ? 100 : 0}
                        height="2px"
                        flex={1}
                        colorScheme="gray"
                        bg="gray.600"
                        transition="width 0.1s ease-in-out, background-color 0.1s ease-in-out" // Smooth transition effect
                    />
                ))}
            </Flex>

            {/* Back Button */}
            <Box position="absolute" top="10vh" width="100%" px={3} zIndex={5}>
                <Button bg="inherit" variant="link" onClick={() => navigate("/home/chatbox1")}>
                    <img src="/icons/Back_Icon_White.svg" alt="back button" />
                </Button>
            </Box>

            {/* Story Content */}
            <Box position="relative" width="100vw" height="100vh">
                <Image
                    src={currentImage}
                    alt={`Image for ${currentStory.title}`}
                    objectFit="cover"
                    height="100%"
                    width="100%"
                    zIndex={1}
                    fallback={<Skeleton height="100%" width="100%" />}
                />
                <Box position="absolute" bottom="1vh" width="100%" px={3} mt="50px">
                    <Box position="relative" zIndex={5}>
                        <Heading maxW="250px" fontWeight={700} fontSize="36px" lineHeight="43px" color="white">
                            {currentStory.title}
                        </Heading>
                        <Text color="white" mt="16px">
                            {currentStory.subtitle}
                        </Text>
                    </Box>
                    <Text mt="16px" color="white">
                        {currentStory.details}
                    </Text>
                </Box>
            </Box>

            {/* Scroll Up Feature */}
            <Flex position="absolute" bottom="20px" width="100%" justifyContent="center" alignItems="center" zIndex={5}>
                <VStack spacing={2}>
                    <Button bg="inherit" variant="link" onClick={handleSwipeUp} animation={`${moveUpDown} 1s infinite`}>
                        <img src="/icons/ArrowUp.svg" alt="swipe up button" />
                    </Button>
                    <Text fontSize="13px" color="#9D9D9D" mb="17px" fontWeight="500">
                        SCROLL UP FOR MORE
                    </Text>
                </VStack>
            </Flex>

            {/* Slide-in Place Component */}
            <Slide direction="bottom" in={isPlaceOpen} style={{ zIndex: 1000000000 }}>
                <Suspense fallback={<div>Loading...</div>}>
                    <PlaceDetail handleBackButton={() => setIsPlaceOpen(false)} />
                </Suspense>
            </Slide>
        </Box>
    );
};

export default Stories;
