//@ts-nocheck
import React, { useEffect, useState, memo } from 'react';
import {
	MapContainer,
	TileLayer,
	Marker,
	Popup,
	ZoomControl,
	useMap,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
	Box,
	Text,
	Avatar,
	useBreakpointValue,
	IconButton,
} from '@chakra-ui/react';
import './trmapleaf.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { CloseIcon } from '@chakra-ui/icons';

const DefaultIcon = L.icon({
	iconUrl: markerIcon,
	iconRetinaUrl: markerIcon2x,
	shadowUrl: markerShadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface Mark {
	latitude: number;
	longitude: number;
	title?: string;
	previewImage?: string;
}

interface MapComponentProps {
	defaultIndex?: number;
	marks?: Mark[];
	defaultFullscreen?: boolean;
	dismissMap?: () => void;
	fullscreen?: boolean;

	showCloseButton?: boolean;
}

const MapComponent: React.FC<MapComponentProps> = ({
	marks = [],
	defaultFullscreen,
	dismissMap,
	fullscreen = false,
	defaultIndex,
	showCloseButton,
}) => {
	const [currentMark, setCurrentMark] = useState(defaultIndex || 0);
	const [isFullscreen, setIsFullscreen] = useState(fullscreen);
	const isMobile = useBreakpointValue({ base: true, md: false });
	console.log('default index', defaultIndex);
	const setFullScreenFunc = (value) => {
		setIsFullscreen(value);
	};

	const FullscreenControl = memo(
		({
			setFullscreen,
			isFullScreen,
		}: {
			setFullscreen: (val: boolean) => void;
		}) => {
			const map = useMap();

			useEffect(() => {
				const onFullscreenChange = () => {
					// @ts-ignore
					const isFullscreen =
						document.fullscreenElement || map.isFullscreen?.();
					setFullscreen(!!isFullscreen);
					dismissMap();
				};

				if (
					!map._controlCorners.bottomright?.querySelector(
						'.leaflet-control-fullscreen',
					) &&
					!showCloseButton
				) {
					L.control
						.fullscreen({
							position: 'bottomright',
							title: 'Expand to fullscreen',
							titleCancel: 'Exit fullscreen',
						})
						.addTo(map);
				}

				map.on('fullscreenchange', onFullscreenChange);
				document.addEventListener('fullscreenchange', onFullscreenChange);

				return () => {
					map.off('fullscreenchange', onFullscreenChange);
					document.removeEventListener('fullscreenchange', onFullscreenChange);
				};
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [map, setFullscreen]);

			return showCloseButton ? (
				<Box
					onClick={() => {
						dismissMap();
					}}
					position='absolute'
					top='20px'
					right='20px'
					zIndex='1000'>
					<IconButton aria-label='Dismiss map' icon={<CloseIcon />} />
				</Box>
			) : null;
		},
	);

	const CenterMapToMarker = memo(() => {
		const map = useMap();
		useEffect(() => {
			if (marks.length > 0 && marks[currentMark]) {
				const { latitude, longitude } = marks[currentMark];
				if (!isNaN(latitude) && !isNaN(longitude)) {
					map.setView([latitude, longitude], map.getZoom(), { animate: true });
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [map, currentMark, marks]);
		return null;
	});

	return (
		<>
			{typeof currentMark === 'number' && (
				<MapContainer
					className='map-modal-map'
					id='map'
					center={
						marks.length > 0
							? [marks[currentMark].latitude, marks[currentMark].longitude]
							: [0, 0]
					}
					zoom={8}
					style={{
						position: 'relative',
						borderRadius: '20px',
						height: '100%',
						width: '100%',
					}}
					zoomControl={false}>
					<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
					{marks.map((mark, index) => (
						<Marker key={index} position={[mark.latitude, mark.longitude]}>
							<Popup>{mark.title}</Popup>
						</Marker>
					))}

					<CenterMapToMarker />
					<ZoomControl position='topleft' />
					<FullscreenControl
						dismissMap={() => dismissMap}
						isFullScreen={isFullscreen}
						setFullscreen={setFullScreenFunc}
					/>

					{(defaultFullscreen || isFullscreen) && marks.length > 0 && (
						<Box
							ml='10px'
							id='mark-cards'
							display='flex'
							flexDir='row'
							gap='20px'
							zIndex={9999}
							position='absolute'
							left={{ base: 0, lg: 10 }}
							bottom={'100px'}
							width='100%'
							overflow='hidden'
							bg={{ base: 'transparent', md: 'transparent' }}>
							<Carousel
								showThumbs={false}
								showIndicators={true}
								showStatus={false}
								selectedItem={currentMark}
								centerMode
								centerSlidePercentage={window.innerWidth < 768 ? 100 : 15}
								infiniteLoop={false}
								swipeable
								emulateTouch>
								{marks.map((mark, index) => (
									<Box
										key={index}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setCurrentMark(index);
										}}
										display='flex'
										flexDir='row'
										bg={
											index === currentMark ? '#fff' : 'rgba(31, 29, 33, 0.33)'
										}
										width={{ base: '160px', md: '150px', lg: '221px' }}
										height={{ base: '60px', md: '40px', lg: '60px' }}
										overflow='hidden'
										border='2px solid #fff'
										borderRadius='12px'
										cursor='pointer'>
										<Avatar
											w='30%'
											height='100%'
											borderRadius='0'
											src={mark.previewImage}
											name={mark.title}
										/>
										<Box
											p='3px'
											display='flex'
											justifyContent='center'
											alignItems='center'
											w='70%'
											height='100%'>
											<Text
												fontSize={isMobile ? '8px' : '12px'}
												color={index === currentMark ? '#000' : '#fff'}>
												{mark.title}
											</Text>
										</Box>
									</Box>
								))}
							</Carousel>
						</Box>
					)}
				</MapContainer>
			)}
		</>
	);
};

export default memo(MapComponent);
