import { useContext} from "react";
import {Box, Avatar, Flex, Image} from "@chakra-ui/react";
import TrCircleButton from "./TrCircleButton";
import { pixels } from "../theme";
import { AppContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";


const TrCircle = ({ children }) => {
    return (
        <TrCircleButton
            w={pixels["28pixels"]}
            h={pixels["28pixels"]}
            minW={pixels["28pixels"]}
            background={"rgba(2, 6, 12, 0.33)"}
            backdropFilter="blur(2px)"
            aria-label="circle button"
        >
            {children}
        </TrCircleButton>
    );
};

const SavedVideoCard = (props) => {
    const { imageUrl, index, boxSize, videoUrl } = props;
    const context = useContext(AppContext);
    const { likeSearches , setLikeSearches } = context;
    const { fetchData } = useAxios();

    const handleLike = async (e) => {
        console.log(videoUrl)
		e.stopPropagation();
		const token = localStorage.getItem('token');
		console.log(token);
		if (token) {
			const body = {
				url : videoUrl
			};

			const dislikeSearch = async () => {
				await fetchData({
					method: 'POST',
					url: 'user/dislikeSearch',
					options: {
						data: { ...body, token: localStorage.getItem('token') },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});
			};

			dislikeSearch();

			const tempLikeSearches = [];
			for (let search of likeSearches) {
				if (
					!(search['url'] === videoUrl)
				) {
					tempLikeSearches.push(search);
				}
			}
			setLikeSearches(tempLikeSearches);
		}
	};


    return (
        <Box
            key={index}
            border="3px solid rgba(49, 48, 48, 1)"
            borderRadius="10px"
            overflow="hidden"
            width="158px"
            height="158px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
        >
            <Flex
                position="absolute"
                top="6px"
                right="6px"
                gap={pixels["5pixels"]}
                zIndex={"10"}
            >
                <TrCircle aria-label="like">
                    <Image
                        w={pixels["14pixels"]}
                        h={pixels["14pixels"]}
                        src="/icons/like.svg"
                        alt="like"
                        onClick={handleLike}
                    />
                </TrCircle>
                <TrCircle aria-label="share">
                    <Image
                        w={pixels["14pixels"]}
                        h={pixels["14pixels"]}
                        src="/icons/share2.svg"
                        alt="share"
                    />
                </TrCircle>
            </Flex>
            <Avatar
                boxSize={boxSize}
                borderRadius="10px"
                src={imageUrl}
                width="100%"
                height="100%"
            />
            <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                zIndex="1"
            >
                <img
                    src="/icons/playVideo.svg"
                    alt="Play Button"
                    width="40"
                    height="40"
                />
            </Box>
        </Box>
    );
};

export default SavedVideoCard;
