import {
  Button,
  Flex,
  IconButton,
  // Text,
  Heading,
  // VStack,
  // Box,
} from "@chakra-ui/react";
import { VideoHighlights } from "./VideoHighlights";

interface InfLocationDetailProps {
  handleBackButton: () => void;
  influencerData: any;
}
const InfLocationDetail: React.FC<InfLocationDetailProps> = ({
  handleBackButton,
  influencerData,
}) => {
  // console.log("One influencer ",JSON.stringify(influencerData));
  return (
    <Flex flexDir={"column"}>
      <Flex
        justify="space-between"
        align="center"
        maxH="68px"
        w={"100%"}
        py="16px"
        visibility={["visible", "visible", "hidden"]}
        mt={6}
      >
        <IconButton
          aria-label="Back"
          icon={<img src="/icons/Back_Icon_White.svg" alt="back" />}
          colorScheme="gray"
          size="lg"
          variant={"link"}
          onClick={handleBackButton}
        />

        <Button bg="inherit" variant="link">
          <img src={"/icons/share3.svg"} alt="share Icon" />
        </Button>
      </Flex>

      {/* <Flex justifyContent={"space-between"} flexDir={"column"}>
        <VStack spacing={"8px"} alignItems={"flex-start"} ml={"14px"}>
          <Heading
            fontFamily="Poppins"
            fontSize="53px"
            fontWeight="600"
            lineHeight="59.23px"
            letterSpacing="-2.054992914199829px"
            textAlign="left"
            bgGradient={
              "linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)"
            }
            bgClip={"text"}
          >
            {influencerData["title"]}
          </Heading>
          <Text
            fontFamily="Poppins"
            fontSize="13px"
            fontWeight="500"
            lineHeight="16.95px"
            letterSpacing="0.30000001192092896px"
            textAlign="left"
            color={"#9D9D9D"}
          >
            {`${influencerData["city"]}, ${influencerData["country"]}`}
          </Text>
          <Text
            color={"#FFFFFFA1"}
            mt={"23px"}
            fontFamily="Poppins"
            fontSize="14px"
            fontWeight="500"
            lineHeight="18.62px"
            textAlign="justify"
            noOfLines={4}
          >
            {influencerData["description"]}
          </Text>
        </VStack>
        <Box
          borderBottom={"1px solid #FFFFFF21"}
          mt={4}
          mb={4}
          w={"-webkit-fill-available"}
        />
      </Flex> */}
        {/* Video Highlights */}
      <Heading
        fontFamily="Poppins"
        fontSize="20px"
        fontWeight="500"
        lineHeight="13px"
        letterSpacing="-0.30000001192092896px"
        textAlign="left"
        color={"#FFFFFFA1"}
        mb={"32px"}
      >
        Video Highlights
      </Heading>
      <VideoHighlights highlights={influencerData?.["highlights"]} />
    </Flex>
  );
};
export default InfLocationDetail;
