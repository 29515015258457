import { Flex, Divider, Box, Heading, VStack, Text, Container } from '@chakra-ui/react';
import MyCountryBucket from '../components/MyCountryBucket';
// import SearchBox from '../components/SearchBox';
import { pixels } from '../theme';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';

const MyBucketList = () => {
	const context = useContext(AppContext);
	const { likeSearches , likeEntities } = context;
	const [countries, setCountries] = useState([]);
	useEffect(() => {
		const uniqueCountries = new Set();

		likeSearches.forEach(item => {
			uniqueCountries.add(item.country);
		});
		likeEntities.forEach(item => {
			uniqueCountries.add(item.country);
		});

		let sortedCountries = Array.from(uniqueCountries);

		const hasUndefined = sortedCountries.includes(undefined);

		sortedCountries = sortedCountries.filter(country => country !== undefined).sort();
	
		if (hasUndefined) {
			const miscIndex = sortedCountries.findIndex(country => country > 'Miscellaneous');
			if (miscIndex !== -1) {
				sortedCountries.splice(miscIndex, 0, undefined);
			} else {
				sortedCountries.push(undefined); // Fallback to end if no match found
			}
		}

		setCountries(sortedCountries);
	
		console.log('this is my sorted countries with undefined preserved: ', sortedCountries);

	}, [ likeSearches , likeEntities]);

	return (
		<Flex
			overflow={'auto'}
			flexDir={'column'}
			alignItems={'center'}
			bg={['none','none','linear-gradient(118deg, #272529 5.68%, #111012 59.38%)']}
			pt={{ 'base': 0, 'lg': pixels['50pixels'], '2xl': pixels['65pixels'] }}
			pb={pixels['150pixels']}
			w='100%'>
			{/* <SearchBox suggestionBackground='black' /> */}
			<Flex
				flexDir={'column'}
				mt={{ 'base': 0, 'lg': '90px', '2xl': '120px' }}
				maxW={{
					'base': '100%',
					'md': pixels['700pixels'],
					'lg': pixels['894pixels'],
					'2xl': pixels['1100pixels'],
				}}
				gap={pixels['30pixels']}
				mx='auto'
				w={'100%'}>
				<Box pt={{ base: pixels['20pixels'], lg: '60px' }} w={'100%'} px='10px'>
					<VStack width={'100%'}>
						<Container display={['none','none', 'contents']}>
						<Heading
							display={{ base: 'none', lg: 'inherit' }}
							w={'100%'}
							color={'white'}>
							Your Bucketlist
						</Heading>
						<Heading
							display={{ base: 'flex', lg: 'none' }}
							flexDir={'column'}
							w={'100%'}
							color={'white'}>
							<Text fontSize={'13px'} color='rgba(157, 157, 157, 1)'>
								Your
							</Text>
							<Text fontSize={'13px'} fontWeight={900}>
								Bucketlist
							</Text>
						</Heading>
						<Divider pt={'10px'} pb={{ base: '0', lg: '20px' }} />
						</Container>
						{countries.map((country, index) => {
							return <MyCountryBucket country={country} index={index} />;
						})}
					</VStack>
				</Box>
			</Flex>
		</Flex>
	);
};

export default MyBucketList;
