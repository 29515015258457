import { useState, useContext, useEffect } from 'react';
import {
	Box,
	Flex,
	Button,
	Image,
	Text,
	SimpleGrid,
	IconButton,
	VStack,
	HStack,
	useDisclosure,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Avatar,
} from '@chakra-ui/react';
import { AppContext } from '../../context/AppContext';
import TrOutlineButton from '../TrOutlineButton';
import TrPrimaryButton from '../TrPrimaryButton';
import { pixels } from '../../theme';
import useAxios from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import CollapsibleText from '../Text/CollapsibleText';

import MapComponent from '../TrMapLeaf/TrMapLeaflet';
import React from 'react';

interface VideoHighlightsItemProps {
	highlights?: any[];
}
// const highlights = [
//     {
//         best: {
//             best_time_to_visit: ["Summer", "Fall"],
//         },
//         city: "La Veta",
//         continent: null,
//         country: "United States",
//         description:
//             "A small town described as a hidden gem, where the group camped in a brewery parking lot after charming the staff.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "",
//             nearby_amenities: "Brewery",
//             what_to_bring: ["Camping gear"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DPx0r6b--PE20sqDqgpEpd6xP6-WG3BCj2VG0b3WhfiXlb_GbyXzbtsyqmm2SKEBe4EowH9EGvl0wbxvGzHKINuxtRLX837MbGkYltpTHXIhIeejur9iGV1KubZxikMfWAJs9-RR5CYe2m3J8j-v3XfiSU5wqwXcRoPOPxze3XwfE7Q&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["La Veta", "Colorado", "hidden gem", "brewery"],
//         title: "La Veta, Colorado",
//     },
//     {
//         best: {
//             best_to_do: [
//                 "Explore rural roads",
//                 "Visit local towns",
//                 "Enjoy the scenery",
//             ],
//         },
//         city: "",
//         continent: null,
//         country: "United States",
//         description:
//             "A group of friends riding a three-seater bike, called a Trandom, across the United States from Oceanside, California to Annapolis, Maryland. They aim to cover 3,000 miles in just over a month.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "Over a month",
//             nearby_amenities: "",
//             what_to_bring: ["Bike gear", "Helmet", "Snacks"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DOxEEIkFWs-pcU5cW1rqcjQ87E5sYY9grTWxcy1yx3K7lZYijKYwlam3PLsmNnfFXvLwi8eztUkrWY8iv5hMU5sVuf--95ttvUjMbG-ue0V4E-aMwQB7QN5Y9JwrRv4a2G_3nn7YuOw6gV8Yrl3f0xCX_e02cxgEON5LWdGJYhYcCnf&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["bike journey", "Trandom", "cross-country", "adventure"],
//         title: "Trandom Bike Journey",
//     },
//     {
//         best: {
//             best_to_do: ["Camping", "Hiking", "Fishing"],
//         },
//         city: "",
//         continent: null,
//         country: "",
//         description:
//             "A campground featuring a playground and two toilets, mentioned humorously for potential bug issues. It is described as boring without certain friends present.",
//         dynamic_fields: {},
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DM6aHzHWNCsMKT84mmVT4bKk2b9Pfv1l2n31jCEaiHWjC_3NwE_plHdpSJJiNDzKv9IP54E5SOQTVUEAJhufj19-MKa9Ga0Bp9LR6_02H1OdKaDXOp8nh4ZGqDPnjPGZ3UkAAngybDdRj1rj5ovgizr68-xHPHFWFzpm83I6Wi0vukR&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 0.0,
//             lng: 0.0,
//         },
//         tags: ["campground", "playground", "toilets", "bugs"],
//         title: "Parrot Cove Campground",
//     },
//     {
//         best: {
//             best_time_to_visit: ["Spring", "Fall"],
//         },
//         city: "Pratt",
//         continent: null,
//         country: "United States",
//         description:
//             "The halfway point of a couple's journey across the United States in a van, marking a significant milestone in their adventure.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "",
//             nearby_amenities: "",
//             what_to_bring: ["Van essentials"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DNaXv41QNOBz5ZirGbvf-Qq3M8mZKuDzRi1XYJhWlH8bxwDk4uanPuIbpJAI6nRbBRFLmLKwdLunxRcUm8ha2ZSVrJR83-6V_j-K7LWpELfNy4Sh4C2zJoD1cmVMMwlOXiiGgDBwKDDDGuwWRzd9Exs5yWzPitxURzbrV4uf4hj8CU&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["Pratt", "Kansas", "halfway point", "van life"],
//         title: "Pratt, Kansas",
//     },
//     {
//         best: {
//             best_to_do: ["Biking", "Hiking", "Horseback Riding"],
//         },
//         city: "",
//         continent: null,
//         country: "United States",
//         description:
//             "A 300-mile bike path running parallel to the group's route, offering a peaceful ride free from cars and traffic.",
//         dynamic_fields: {
//             best_time_to_visit: "",
//             duration: "",
//             nearby_amenities: "",
//             what_to_bring: ["Bike gear", "Water"],
//         },
//         identity_tag: "",
//         image:
//             "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=AdCG2DNvx9V3K96Jf6EfKU_WyP6e58VE7cQ6FffPuLFHngDIf12NxXPrPF-NkWSDY26p0JEgIJWkxdH-ZJHrY1GaN4IrdOUU0CdU1ZYqEMPSbpxYfyWPhvqUwRxh8xP29o7GXnohDkfCVpGbSjNIgMflWCL1lW2ljAd3gQ8HleMPo_lz9rJ6&key=AIzaSyCzXq7YDZ6T1d-92O39HHyyAuapVRE3two",
//         location: {
//             lat: 54.5259614,
//             lng: -105.2551187,
//         },
//         tags: ["Katy Trail", "bike path", "Missouri", "cycling"],
//         title: "Katy Trail",
//     },
// ];
export const VideoHighlights: React.FC<VideoHighlightsItemProps> = ({
	highlights,
}) => {
	const context: any = useContext(AppContext);
	const [showAddedToBucket, setShowAddedToBucket] = useState(false);
	const { likeEntities, setLikeEntities } = context;
	const [highLightIndex, setHighlightIndex] = useState(0);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { fetchData } = useAxios();
	const [likedItems, setLikedItems] = useState<{ [key: string]: boolean }>({});

	const navigate = useNavigate();

	useEffect(() => {
		// Initialize likedItems state based on previously liked entities
		const initialLikedItems = likeEntities.reduce(
			(acc: { [key: string]: boolean }, item: any) => {
				acc[item.title] = true;
				return acc;
			},
			{},
		);
		setLikedItems(initialLikedItems);
	}, [likeEntities]);

	const handleLike = async (item: any) => {
		const token = localStorage.getItem('token');
		if (token) {
			// Check if the item is already liked
			const isLiked = likedItems[item.title];

			// Prepare the item data for likeEntities
			const body = {
				previewImage: item?.image,
				city: item?.city === '' ? undefined : item?.city,
				country: item?.country === '' ? undefined : item?.country,
				title: item?.title,
				tags: item?.tags,
				description: item?.description,
			};
			console.log('body of highlights ', body);

			try {
				// Update the likeEntities based on the current like state
				const updatedLikeEntities = isLiked
					? likeEntities.filter(
							(likedItem: any) => likedItem.title !== item.title,
					  ) // Remove if unliked
					: [...likeEntities, body]; // Add if liked

				// Make the appropriate API call
				setLikeEntities(updatedLikeEntities);
				setLikedItems((prev) => ({ ...prev, [item.title]: !isLiked }));

				if (isLiked) {
					// Dislike the item
					await fetchData({
						method: 'POST',
						url: 'user/dislikeEntity',
						options: {
							data: { ...body, token: localStorage.getItem('token') },
							headers: { 'Content-Type': 'application/json' },
						},
						auth: false,
					});
				} else {
					// Like the item
					await fetchData({
						method: 'POST',
						url: 'user/likeEntity',
						options: {
							data: { ...body, token: localStorage.getItem('token') },
							headers: { 'Content-Type': 'application/json' },
						},
						auth: false,
					});
					setShowAddedToBucket(true);

					setTimeout(() => {
						setShowAddedToBucket(false);
					}, 3000);
				}
			} catch (error) {
				console.error('Error while liking/disliking the item:', error);
			}
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent zIndex={9999999} mt={'0'} p={0} height={'100%'}>
					<ModalBody p={0} m={0} height={'100%'}>
						<MapComponent
							showCloseButton={true}
							defaultIndex={highLightIndex}
							defaultFullscreen={true}
							// isMobile={true}
							dismissMap={() => {
								console.log('ok');
								onClose();
							}}
							marks={
								highlights
									? highlights?.map((highlight: any) => ({
											latitude:
												highlight &&
												highlight?.location &&
												isNaN(highlight?.location)
													? parseFloat(highlight?.location?.lat)
													: 0,
											longitude:
												highlight &&
												highlight?.location &&
												isNaN(highlight?.location)
													? parseFloat(highlight?.location?.lng)
													: 0,
											title: highlight?.title,
											previewImage: highlight?.image,
									  }))
									: []
							}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
			<>
				<SimpleGrid columns={2} spacing={5}>
					{highlights?.map((item: any, index: number) => (
						<Box
							color='white'
							borderRadius='lg'
							overflow='hidden'
							boxShadow='lg'
							w={'auto'}>
							<Box position='relative'>
								<Avatar
									src={item.image}
									name={item.title}
									borderRadius='lg'
									w='100%'
									h='150px'
									objectFit='cover'
								/>
								<Flex position='absolute' top='6px' right='6px'>
									<IconButton
										aria-label='Like'
										icon={
											<Image
												src={
													likedItems[item.title]
														? '/icons/likeh.svg'
														: '/icons/whiteLikeh.svg'
												}
												alt='like'
											/>
										}
										variant='ghost'
										onClick={() => handleLike(item)}
										dropShadow='0px 4px 10px rgba(0, 0, 0, 0.6)'
										_hover={{ bg: 'transparent' }}
										_active={{ bg: 'transparent' }}
										_focus={{ boxShadow: 'none' }}
									/>
									<IconButton
										aria-label='Share'
										icon={<Image src='/icons/shareh.svg' alt='share' />}
										variant='ghost'
										_hover={{ bg: 'transparent' }}
										_active={{ bg: 'transparent' }}
										_focus={{ boxShadow: 'none' }}
									/>
								</Flex>
							</Box>
							<VStack alignItems={'space-between'} ml='1' pt={3} spacing='2'>
								<HStack
									justifyContent={'space-between'}
									position={'relative'}
									alignItems={'flex-start'}>
									<Text
										fontFamily='Poppins'
										fontSize='13px'
										fontWeight='700'
										lineHeight='16.25px'
										textAlign='left'
										minHeight='32.5px'
										whiteSpace='break-spaces'
										noOfLines={2}
										w={'70%'}
										color={'#FFFFFFED'}>
										{item.title}
									</Text>

									<Box
										display='inline-flex'
										alignItems='center'
										justifyContent='center'
										borderRadius='50%'
										bg='gray.900' // You can change this to your preferred color
										p={2} // Padding to create the circular effect
										onClick={() => {
											setHighlightIndex(index);
											onOpen();
										}}>
										<Image
											src='/icons/map_pin.svg'
											alt='map icon'
											boxSize={4}
										/>
									</Box>
								</HStack>
								<CollapsibleText
									fontFamily='Poppins'
									fontSize='13px'
									fontWeight='400'
									lineHeight='17.55px'
									textAlign='left'
									color={'#FFFFFFA1'}
									content={item.description}
									maxLines={4}
								/>
								{item.dynamic_fields &&
									Object.entries(item.dynamic_fields)
										.slice(0, 2)
										.map(([key, value]) => (
											<React.Fragment key={key}>
												<Text
													noOfLines={2}
													fontFamily='Poppins'
													fontSize='13px'
													fontWeight='700'
													lineHeight='19.5px'
													textAlign='left'
													color={'#FFFFFFD4'}>
													{
														key
															.replace(/_/g, ' ') // Replace underscores with spaces
															.replace(/^./, (char) => char.toUpperCase()) // Capitalize only the first letter
													}{' '}
													-{' '}
													<Text
														as={'span'}
														fontFamily='Poppins'
														fontSize='13px'
														fontWeight='500'
														lineHeight='19.5px'
														textAlign='left'
														color={'#FFFFFFA1'}>
														{
															Array.isArray(value)
																? (value as string[]).join(', ') // Cast to string array
																: (value as string) // Cast to string
														}
													</Text>
												</Text>
											</React.Fragment>
										))}
								<Flex gap={'8px'}>
									<TrOutlineButton
										w={'50%'}
										h={pixels['34pixels']}
										fontSize={'13px'}>
										Details
									</TrOutlineButton>{' '}
									<TrPrimaryButton
										w={'50%'}
										h={pixels['34pixels']}
										fontSize={'13px'}>
										Book
									</TrPrimaryButton>{' '}
								</Flex>
							</VStack>
						</Box>
					))}
					{/* {highlights?.map((item, index) => (
                <Box key={index} display={"block"}>
                    <Flex
                        position="relative"
                        width="100%"
                        flexDir="column"
                        gap={{ base: "11px", "2xl": "13px" }}
                    >
                        <Image
                            src={item.image}
                            alt="video highlight"
                            borderRadius="20px"
                            objectFit="cover"
                            backgroundColor="white"
                            w="100%"
                            h="130px"
                            border="none"
                        />

                        <Flex position="absolute" top="6px" right="6px" gap="5px">
                            <IconButton
                                aria-label="Like"
                                icon={<Image src={likedItems[item.title] ? "/icons/likeh.svg" : "/icons/whiteLikeh.svg"} alt="like" />}
                                variant="ghost"
                                size="lg"
                                onClick={() => handleLike(item)}
                                borderRadius="full"
                                dropShadow="0px 4px 10px rgba(0, 0, 0, 0.6)"
                                _hover={{ bg: "transparent" }}
                                _active={{ bg: "transparent" }}
                                _focus={{ boxShadow: "none" }}
                            />
                            <IconButton
                                aria-label="Share"
                                icon={<Image src="/icons/shareh.svg" alt="share" />}
                                variant="ghost"
                                size="lg"
                                borderRadius="full"
                                _hover={{ bg: "transparent" }}
                                _active={{ bg: "transparent" }}
                                _focus={{ boxShadow: "none" }}
                            />
                        </Flex>

                        <Flex justifyContent={'space-between'}>
                            <Text
                                color='rgba(255, 255, 255, 0.93)'
                                fontFamily='Poppins'
                                fontSize='14px'
                                fontWeight='800'
                                my='auto'
                                lineHeight='125%'
                                flex={4}>
                                {item?.title}
                            </Text>
                            <Box
                                onClick={() => {
                                    if (item.onOpenMobileMap) {
                                        console.log('hi', item);
                                        item?.onOpenMobileMap({
                                            //@ts-ignore
                                            longitude: item.location?.lng || 0,
                                            //@ts-ignore
                                            latitude: item.location?.lat || 0,
                                            title: item?.title,
                                            previewImage: item.previewImage,
                                        });
                                    }
                                }}
                                sx={{
                                    borderRadius: '50%',
                                    background: 'rgba(255, 255, 255, 0.13)',
                                    backdropFilter: 'blur(2px)',
                                    h: '25px',
                                    w: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Image src='/icons/map_pin.svg' alt='map_pin' />
                            </Box>
                        </Flex>
                        <Text
                            minH={pixels['55pixels']}
                            color='rgba(255, 255, 255, 0.63)'
                            fontFamily='Poppins'
                            fontSize={pixels['14pixels']}
                            fontStyle='normal'
                            fontWeight='500'
                            my='auto'
                            lineHeight='135%'
                            noOfLines={2}>
                            {item?.description}
                        </Text>
                        <Flex flexDir={'column'} gap={'10px'}>
                            <HighlightTitle
                                title='Vibe'
                                description={item?.tags?.join(', ') || 'N/A'}
                            />
                            <HighlightTitle
                                title={
                                    item?.best_title
                                        ?.replace(/_/g, ' ')
                                        .replace(/\b\w/g, (char: string) => char.toUpperCase()) || ''
                                }
                                description={
                                    item?.best_value ? item?.best_value?.join(', ') : ''
                                }
                            />
                        </Flex>
                        <Flex gap={'8px'}>
                            <TrOutlineButton w={'50%'} h={pixels['34pixels']} fontSize={'13px'}>
                                Details
                            </TrOutlineButton>{' '}
                            <TrPrimaryButton w={'50%'} h={pixels['34pixels']} fontSize={'13px'}>
                                Book
                            </TrPrimaryButton>{' '}
                        </Flex>
                    </Flex>
                </Box>
            ))} */}
				</SimpleGrid>
				{showAddedToBucket && (
					<Flex
						position='fixed'
						bottom='10px'
						left='50%'
						transform='translateX(-50%)'
						bg='#AE7CFF'
						w={'300px'}
						h={'40px'}
						color='white'
						borderRadius='8px'
						alignItems={'center'}
						justifyContent={'space-around'}
						zIndex={200}>
						<Text
							fontFamily='Poppins'
							fontSize='18px'
							fontWeight={'medium'}
							lineHeight='19px'
							letterSpacing='-0.5611507892608643px'
							textAlign='left'>
							Added to bucket list
						</Text>
						<Button
							variant={'ghost'}
							color={'white'}
							_hover={{ bg: 'none' }}
							fontWeight={'medium'}
							onClick={() => navigate('/home/navigate')}>
							View
						</Button>
					</Flex>
				)}
			</>
		</>
	);
};
