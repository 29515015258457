import {  Heading, HStack, IconButton, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface HeaderMobileProps {
    isAuthenticated: boolean;
    onOpenLogin: () => void;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ isAuthenticated, onOpenLogin }) => {
    const navigate = useNavigate();
    return (
        <HStack bg={'#29252D'} px={6} pb={6} pt={6} w={'100vw'} justifyContent={'space-between'} borderBottomRadius={'33px'} >
            <HStack>
                <Image src='/icons/logo.svg' alt="logo" boxSize={'18px'} />
                <Heading
                    fontFamily="Poppins"
                    fontSize="16.78px"
                    fontWeight="600"
                    lineHeight="20.28px"
                    letterSpacing="-0.2097432017326355px"
                    textAlign="left"
                    onClick={() => { navigate('/home') }}
                    color={'#FFFFFFED'} >
                    TRAVERSE
                </Heading>
            </HStack>
            <HStack>

                <IconButton
                    aria-label="saved-Chat"
                    icon={<img src="/icons/chat.svg" alt="chat" />}
                    borderRadius={'9.03px'}
                    color="white"
                    bg="#2E2B31"
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                    onClick={isAuthenticated ? () => navigate('/home/savedConversation') : onOpenLogin}
                />

                {isAuthenticated ? (
                    <Image
                        src={"/images/user.png"}
                        alt="user"
                        boxSize="42px"
                        borderRadius="full"
                        objectFit={"cover"}
                        border={"2px solid #8A63C8"}
                        onClick={() => { navigate('/home/profile') }}
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                    />
                ) :
                    (<IconButton
                        aria-label="Log-in"
                        icon={<img src="/icons/user.svg" alt="chat" />}
                        borderRadius={'9.03px'}
                        color="white"
                        bg="#2E2B31"
                        onClick={onOpenLogin}
                        _hover={{ bg: "transparent" }}
                        _active={{ bg: "transparent" }}
                        _focus={{ boxShadow: "none" }}
                    />
                    )
                }

            </HStack>
        </HStack>
    );
};
export default HeaderMobile;
