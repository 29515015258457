import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Slide,
  Skeleton,
  HStack,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useSwipeable } from "react-swipeable";
import ReactPlayer from "react-player";
import Header from "./Header";
import PlacesTabs from "./PlaceTabs";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { fetchEntityDetails } from "../../services/apiService";
import { useNavigate } from "react-router-dom";

interface Story {
  title: string;
  city: string;
  country: string;
  continent: string;
  location: {
    lat: number;
    long: number;
  };
  description: string;
  image?: string[];
  videos?: string[];
  tags: string[];
  otherDetail?: any[];
}

const defaultStories: Story[] = [
  {
    title: "",
    city: "",
    country: "",
    continent: "",
    location: { lat: 0, long: 0 },
    description: "",
    tags: ["", ""],
    image: [""],
  }
];

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const LocationDetail = lazy(() => import("./LocationDetail"));

const StoryCarousel: React.FC = () => {
  const context: any = useContext(AppContext);
  const { fetchData } = useAxios();
  const { likeEntities, setLikeEntities } = context;
  const [storyIndex, setStoryIndex] = useState(0);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [showNewPage, setShowNewPage] = useState(false);
  const [stories, setStories] = useState<Story[]>(defaultStories);
  const [key, setKey] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [showAddedToBucket, setShowAddedToBucket] = useState(false)
  const navigate = useNavigate();

  const location = useLocation();
  const initialEntityDetail = location.state?.initialEntityDetail;

  // eslint-disable-next-line
  const allEntities = location.state?.allEntities ?? [{ title: "", image: "" }];

  const likeButtonRef = useRef<HTMLButtonElement>(null);
  const shareButtonRef = useRef<HTMLButtonElement>(null);

  const currentStory = stories[storyIndex];
  const isVideoStory = currentStory.videos !== undefined;
  const isImageStory = currentStory.image !== undefined;
  const currentMedia = isVideoStory
    ? currentStory.videos![mediaIndex]
    : isImageStory
      ? currentStory.image![mediaIndex]
      : undefined;

  const handleNextMedia = () => {
    if (isVideoStory && currentStory.videos!.length > mediaIndex) {
      setMediaIndex(mediaIndex + 1);
    } else if (isImageStory && currentStory.image!.length > mediaIndex) {
      setMediaIndex(mediaIndex + 1);
    } else {
      handleNextStory();
    }
  };

  const handleNextStory = () => {
    if (storyIndex < stories.length - 1) {
      setStoryIndex(storyIndex + 1);
      setMediaIndex(0);
    }
  };

  const handlePreviousStory = () => {
    if (storyIndex > 0) {
      setStoryIndex(storyIndex - 1);
      setMediaIndex(0);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNextStory,
    onSwipedRight: handlePreviousStory,
    onSwipedUp: () => setShowNewPage(true),
    onTap: (event) => {
      // Access native event's target
      const target = event.event.target as Node;

      // Prevent onTap if the target is the like or share button
      if (
        (likeButtonRef.current && likeButtonRef.current.contains(target)) ||
        (shareButtonRef.current && shareButtonRef.current.contains(target))
      ) {
        return;
      }
      handleNextMedia();
    },
    trackMouse: true,
  });

  const handleTabClick = (index: number) => {
    setStoryIndex(index);
    setMediaIndex(0);
  };

  useEffect(() => {
    if (initialEntityDetail && allEntities) {
      const formatStory = (entityDetail: any) => ({
        title: entityDetail.title,
        city: entityDetail.city,
        country: entityDetail.country,
        continent: entityDetail.continent,
        location: entityDetail.location,
        description: entityDetail.description,
        image: [entityDetail.image],
        tags: entityDetail.tags,
        otherDetail: entityDetail.dynamic_fields,
      });

      console.log("allEntities ", allEntities);
      console.log("initialEntityDetail ", JSON.stringify(initialEntityDetail.entity));

      const initialIndex = allEntities.findIndex(
        (entity: { title: string; }) => entity.title === initialEntityDetail.entity.title
      );

      console.log("initialIndex ", initialIndex);
      setStoryIndex(initialIndex);

      const initialStory = formatStory(initialEntityDetail.entity);
      let newStories = Array(allEntities.length).fill(defaultStories);
      newStories[initialIndex] = initialStory;
      setStories(newStories);

      const fetchOtherTitles = async () => {
        try {
          const otherDetails = await Promise.allSettled(
            allEntities.map((entity: { title: string; }, index: number) =>
              stories[index]?.title?.length > 0
                ? Promise.resolve(stories[index])
                : fetchEntityDetails(entity.title)
                  .then(response => {
                    if (response.status === 200) {
                      return response.data.entity;
                    } else {
                      return (defaultStories)

                    }
                  })
            )
          );

          // Filter for successful responses and map them to the expected format
          console.log("other details is ", JSON.stringify(otherDetails));
          const orderedStories = otherDetails
            .filter((result): result is PromiseFulfilledResult<any> => result.status === "fulfilled")
            .map(result => formatStory(result.value));


          setStories(orderedStories.length > 0 ? orderedStories : defaultStories);
        } catch (error) {
          console.error("Error fetching titles:", error);
        }
      };


      fetchOtherTitles();
    }
    // eslint-disable-next-line
  }, [allEntities, initialEntityDetail]);



  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [storyIndex]);

  useEffect(() => {
    document.body.style.overflow = showNewPage ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showNewPage]);

  useEffect(() => {
    console.log("check??");
    setIsLiked(false);
    for (let entity of likeEntities) {
      if (entity["title"] === currentStory.title) {
        setIsLiked(true);
      }
    }
  }, [likeEntities, currentStory]);

  const handleLike = async () => {
    const token = localStorage.getItem("token");
    if (token && currentStory) {
      // console.log(props)
      const body = {
        previewImage: currentStory?.image?.[0],
        city: currentStory?.city,
        country: currentStory?.country,
        title: currentStory?.title,
        tags: currentStory?.tags,
        description: currentStory?.description,
      };
      console.log("body ", body);

      if (!isLiked) {
        await fetchData({
          method: "POST",
          url: "user/likeEntity",
          options: {
            data: { ...body, token: localStorage.getItem("token") },
            headers: { "Content-Type": "application/json" },
          },
          auth: false,
        });

        setLikeEntities([...likeEntities, body]);

        setShowAddedToBucket(true)

        setTimeout(() => {
          setShowAddedToBucket(false);
        }, 3000);

      } else {
        await fetchData({
          method: "POST",
          url: "user/dislikeEntity",
          options: {
            data: { ...body, token: localStorage.getItem("token") },
            headers: { "Content-Type": "application/json" },
          },
          auth: false,
        });

        const tempLikeEntities = [];
        for (let entity of likeEntities) {
          if (!(entity["title"] === body.title)) {
            tempLikeEntities.push(entity);
          }
        }
        setLikeEntities(tempLikeEntities);
      }

      //     const tempLikeEntities = [];
      //     for (let entity of likeEntities) {
      //         if (
      //             !(entity['title'] === currentStory.title)
      //         ) {
      //             tempLikeEntities.push(entity);
      //         }
      //     }
    }
  };
  const handleLikeButton = (e: React.MouseEvent) => {
    e.stopPropagation(); // Stop the event from bubbling up
    setIsLiked(!isLiked);
    handleLike();
  };
  const handleShare = () => {
    console.log("share button clicked");
  };

  return (
    <Box
      width="100%"
      height="100vh"
      overflowY="auto"
      position="relative"
      bg="#040D10"
      color="white"
    >
      <Header />
      <PlacesTabs
        storyIndex={storyIndex}
        onTabClick={handleTabClick}
        allEntities={allEntities}
      />

      {/* Fullscreen overlay to prevent interactions when LocationDetail is open */}
      {showNewPage && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg="blackAlpha.700"
          zIndex={10}
          onClick={(e) => e.stopPropagation()} // Prevent clicks from reaching underlying elements
        />
      )}

      <Box
        {...(!showNewPage ? handlers : {})}
        position="relative"
        cursor="pointer"
        my={0}
        mx={4}
        width="92vw"
        height="80vh"
        borderRadius="12px"
        overflow="hidden"
        zIndex={showNewPage ? 0 : 1} // Ensure zIndex order
      >
        {showAddedToBucket && (
          <Flex
            position="fixed"
            bottom="10px"
            left="50%"
            transform="translateX(-50%)"
            bg="#AE7CFF"
            w={"300px"}
            h={"40px"}
            color="white"
            borderRadius="8px"
            alignItems={"center"}
            justifyContent={"space-around"}
            zIndex={5}
          >
            <Text
              fontFamily="Poppins"
              fontSize="18px"
              fontWeight={"medium"}
              lineHeight="19px"
              letterSpacing="-0.5611507892608643px"
              textAlign="left"
            >
              Added to bucket list
            </Text>
            <Button
              variant={"ghost"}
              color={"white"}
              _hover={{ bg: "none" }}
              fontWeight={"medium"}
              onClick={() => navigate("/home/navigate")}
            >
              View
            </Button>
          </Flex>
        )}

        {isVideoStory ? (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ReactPlayer
              url={currentMedia}
              playing={true}
              muted={false}
              loop={false}
              controls={false}
              onEnded={handleNextMedia}
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
              config={{
                file: {
                  attributes: {
                    style: {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  },
                },
              }}
            />
          </Box>
        ) : (
          isImageStory && (
            <Image
              src={currentMedia}
              alt={currentStory.title}
              width="100%"
              height="100%"
              objectFit="cover"
            />
          )
        )}

        <Box position="absolute" top="10px" right="10px" zIndex={100}>
          <HStack spacing={0} justifyContent="flex-end">
            <IconButton
              aria-label="Like"
              ref={likeButtonRef}
              icon={
                <Image
                  src={isLiked ? "/icons/like.svg" : "/icons/white-heart.svg"}
                  alt="like"
                />
              }
              variant="ghost"
              size="lg"
              onClick={handleLikeButton}
              borderRadius="full"
              dropShadow="0px 4px 10px rgba(0, 0, 0, 0.6)" // Customize shadow here
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
            />
            <IconButton
              aria-label="Share"
              ref={shareButtonRef}
              icon={<Image src="/icons/white-share.svg" alt="share" />}
              variant="ghost"
              onClick={handleShare}
              size="lg"
              borderRadius="full"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
            />
          </HStack>
        </Box>

        {(isVideoStory || isImageStory) && (
          <Flex
            position="absolute"
            bottom="35%"
            left="50%"
            transform="translate(-50%, 35%)"
            justifyContent="center"
            alignItems="center"
            zIndex={2}
          >
            {(isVideoStory ? currentStory.videos : currentStory.image)!.map(
              (_, index) => (
                <Box
                  key={index}
                  width="8px"
                  height="8px"
                  mx={1}
                  borderRadius="50%"
                  backgroundColor={mediaIndex === index ? "white" : "gray"}
                  opacity={mediaIndex === index ? 1 : 0.5}
                  transition="opacity 0.3s ease"
                />
              )
            )}
          </Flex>
        )}

        <Box
          position="absolute"
          bottom={0}
          width="100%"
          p={4}
          fontFamily="Poppins"
          bgGradient="linear(to-t, blackAlpha.800, transparent)"
        >
          <Text
            fontSize="26.54px"
            fontWeight="500"
            lineHeight="29.66px"
            letterSpacing="-1.0290316343307495px"
            bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(192, 192, 192, 0.93) 95.69%)"
            bgClip="text"
            textAlign="left"
          >
            {currentStory.title}
          </Text>
          <Text
            fontSize="13px"
            fontWeight="500"
            lineHeight="16.95px"
            letterSpacing="0.3px"
            textAlign="left"
          >
            {currentStory.city}
          </Text>
          <Box zIndex={3} borderRadius="12px">
            <Text
              fontSize="14px"
              fontWeight="500"
              mt={10}
              lineHeight="19px"
              noOfLines={3}
              letterSpacing="-0.7px"
              textAlign="justify"
              bgGradient="linear-gradient(180deg, #FFFFFF 14.74%, rgba(15, 15, 15, 0) 119.41%)"
              bgClip="text"
            >
              {currentStory.description}
            </Text>
          </Box>
          <HStack
            spacing={0}
            position={"relative"}
            zIndex={11}
            justifyContent={"center"}
            animation={`${moveUpDown} 1s infinite`}
          >
            <Text fontSize="13px" color="gray.400" fontWeight="300">
              {"SWIPE UP FOR MORE    "}
            </Text>
            
              <Image src="/icons/ArrowUp.svg" alt="swipe up" />
            
          </HStack>
        </Box>
      </Box>

      <Slide direction="bottom" in={showNewPage} style={{ zIndex: 11 }}>
        <Box p={4} bg="#000000" h="100dvh" overflowY="auto">
          <Suspense fallback={<Skeleton height="100vh" width="100vw" />}>
            <LocationDetail
              key={key}
              handleBackButton={() => setShowNewPage(false)}
              story={currentStory}
            />
          </Suspense>
        </Box>
      </Slide>
    </Box>
  );
};

export default StoryCarousel;
