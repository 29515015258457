import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Grid } from "@chakra-ui/react";
import SavedVideoCard from "./SavedVideoCard";

const SavedVideos = () => {
    const context = useContext(AppContext);
    const { likeSearches, bucketListCard } = context;
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const tempSavedVideos = [];
        for (let search of likeSearches) {
            if (
                search["country"] === bucketListCard[0] &&
                search["city"] === undefined
                    ? "Other"
                    : search["city"] === bucketListCard[1]
            ) {
                tempSavedVideos.push(search);
            }
        }
        setVideos(tempSavedVideos);
    }, [likeSearches, bucketListCard]);

    return (
        <Grid
            templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(4, 1fr)",
            }}
            gap={4}
        >
            {videos.map((video, index) => (
                <SavedVideoCard index={index} boxSize={'158px'} imageUrl ={video['previewImage']} videoUrl = {video['url']}/>
            ))}
        </Grid>
    );
};

export default SavedVideos;
