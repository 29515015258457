import { Tabs, TabList, Tab, HStack, Box, Image, Text } from '@chakra-ui/react';
import { FC, useRef, useEffect } from 'react';

interface PlacesTabsProps {
  storyIndex: number;
  onTabClick: (index: number) => void;
  allEntities: any[]; // Updated type to string[] for clarity
}

const PlacesTabs: FC<PlacesTabsProps> = ({ storyIndex, onTabClick, allEntities }) => {
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    // Scroll to the focused tab when `storyIndex` changes
    tabRefs.current[storyIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }, [storyIndex]);

  return (
    <Tabs index={storyIndex} variant="unstyled">
      <TabList
        gap="12px"
        overflowX="auto"
        whiteSpace="nowrap"
        pl={4}
        my={4}
        pb={2}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        {allEntities.map((entity, index) => (
          <Tab
            key={index}
            ref={(el) => (tabRefs.current[index] = el)}
            borderRadius="lg"
            m={1}
            p={3}
            bg="#FFFFFF0D"
            maxW="175px"
            fontWeight="400"
            flexShrink={0}
            _selected={{ bg: "#FFFFFF21", border: "1px solid #FFFFFF54", fontWeight: "700" }}
            _hover={{ bg: "#FFFFFF16" }}
            onClick={() => onTabClick(index)}
          >
            <HStack spacing="11px">
              <Image
                src={entity.image}
                boxSize="27px"
                borderRadius="md"
              />
              <Box>
                <Text
                  fontFamily="Poppins"
                  fontSize="11px"
                  lineHeight="16.5px"
                  textAlign="left"
                  color="#FFFFFF"
                  whiteSpace="normal"
                  dangerouslySetInnerHTML={{ __html: `${entity.title}` }}
                />
              </Box>
            </HStack>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default PlacesTabs;
