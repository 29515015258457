import React, { useContext } from 'react';
import { Box, Flex, Skeleton, useDisclosure } from '@chakra-ui/react';
import { pixels } from '../theme';
import CityFlightDetail from '../components/CityFlightDetail';
import TopHighlights from '../components/TopHighlights';
import ImageSlider from '../components/ImageSlider';
import VideoTags from '../components/VideoTags';
// import ScrollNextButton from '../components/ScrollNextButton';
import ModalWithBackdrop from '../components/ModalWithBackdrop';
import CountryPageDetail from '../components/CountryPageDetail';
import { AppContext } from '../context/AppContext';
import VideoHeader from '../components/VideoHeader';
import { Link } from 'react-scroll';
import TrMapLeaflet from '../components/TrMapLeaf/TrMapLeaflet';

const SearchPageSection: React.FC<any> = () => {
	const {
		searchDetail,
		searchDetailLoading,
		videoHighlightDetail,
		currentVideoIndex,
	}: any = useContext(AppContext);

	const {
		isOpen: isOpenDetailsModal,
		onOpen: onOpenDetailsModal,
		onClose: onCloseOpenDetailsModal,
	} = useDisclosure();

	return (
		<Box
			position={'relative'}
			top={0}
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
			// maxH={{ base: '90vh', lg: '80vh' }}
			height={{
				base: '100%',
				md: pixels['700pixels'],
				lg: '100%',
				// '2xl': pixels['1100pixels'],
			}}>
			<Box
				height={'100%'}
				width={'100vw'}
				display={'flex'}
				flexDir={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				position={'relative'}
				px={{ base: '0', lg: 0 }}
				mx='auto'>
				<ModalWithBackdrop
					isCentered={false}
					isOpen={isOpenDetailsModal}
					onClose={onCloseOpenDetailsModal}>
					<CountryPageDetail
						videoHighlightDetail={videoHighlightDetail}
						searchDetail={searchDetail}
					/>
				</ModalWithBackdrop>

				<ModalWithBackdrop
					isCentered={false}
					isOpen={isOpenDetailsModal}
					onClose={onCloseOpenDetailsModal}>
					<CountryPageDetail
						videoHighlightDetail={videoHighlightDetail}
						searchDetail={searchDetail}
					/>
				</ModalWithBackdrop>
				<Skeleton
					display={{ base: 'none', lg: 'flex' }}
					height={{
						base: '100%',
						md: '100px',
						lg: '80px',
						// '2xl': '140px',
					}}
					alignItems={'center'}
					width={'100%'}
					minH={'70px'}
					mt={searchDetailLoading ? '20px' : 'inherit'}
					isLoaded={!searchDetailLoading}>
					<Box
						position={{ base: 'absolute', lg: 'unset' }}
						// top={60}
						// marginY={{ 'lg': '20px', '2xl': '20px' }}
						left={2}
						zIndex={1}
						display={{ base: 'none', lg: 'block' }}>
						{searchDetail &&
						searchDetail?.videos &&
						searchDetail?.videos[currentVideoIndex] ? (
							<VideoTags
								videoTags={
									searchDetail && searchDetail?.videos
										? searchDetail?.videos[currentVideoIndex || 0].tags
										: []
								}
							/>
						) : (
							''
						)}
					</Box>
				</Skeleton>
				<Flex
					minHeight={{ base: 'inherit', lg: 'inherit' }}
					flexDir={{ base: 'column', md: 'row' }}
					w={{ 'base': '100%', 'lg': `100%`, '2xl': '1100px' }}
					gap={{ base: '20px', lg: '20px', xl: '40px' }}>
					<Skeleton
						mt={searchDetailLoading ? '20px' : 'inherit'}
						height={{ base: '100%', md: '530px', lg: '530px' }}
						minHeight={{ xs: '100vh', md: '530px', lg: '530px' }}
						minW={'270px'}
						width={'100%'}
						isLoaded={!searchDetailLoading}>
						<Flex
							minH={{ base: '100%', lg: 'inherit' }}
							position={'relative'}
							maxW={{
								'base': '100%',
								'md': '244px',
								'lg': `230px`,
								'2xl': '300px',
							}}
							display={{ base: 'flex', md: 'flex' }}
							flexDir={'column'}
							gap={pixels['20pixels']}>
							<Box
								display={{ base: 'inherit', lg: 'none' }}
								w='100%'
								position={'absolute'}
								bottom={'-60px'}
								zIndex={51}
								p={pixels['12pixels']}>
								<VideoHeader videos={searchDetail?.videos} />
							</Box>

							<ImageSlider items={searchDetail?.videos} />
						</Flex>
					</Skeleton>
					<Flex
						alignItems={{ base: 'center', md: 'inherit' }}
						justifyContent={{ base: 'center', md: 'inherit' }}
						// px={{ base: pixels['20pixels'], lg: 0 }}
						flexDir={'column'}
						gap={{ 'base': pixels['16pixels'], '2xl': pixels['20pixels'] }}>
						<Flex
							justifyContent={'space-between'}
							width={'95%'}
							flexDir={{ base: 'column', md: 'row' }}
							gap={pixels['20pixels']}>
							<Skeleton
								mt={searchDetailLoading ? '20px' : 0}
								isLoaded={!searchDetailLoading}>
								<Box display={{ base: 'none', lg: 'inherit' }}>
									<CityFlightDetail onOpenDetailsModal={onOpenDetailsModal} />
								</Box>
							</Skeleton>
							<Skeleton
								zIndex={1}
								mt={searchDetailLoading ? '20px' : 0}
								isLoaded={!searchDetailLoading}>
								<Box
									mb={{ base: '100px', md: '0' }}
									borderRadius={pixels['20pixels']}
									overflow={'hidden'}
									display={{ base: 'none', lg: 'inherit' }}
									position={'relative'}
									h={{
										'base': '180px',
										'lg': `calc(162px * 0.8)`,
										'2xl': '200px',
									}}
									w={{
										'base': '100%',
										'lg': `calc(313px * 0.8)`,
										'2xl': '386px',
									}}>
									{searchDetail &&
									searchDetail?.videos &&
									searchDetail?.videos[currentVideoIndex] &&
									searchDetail?.videos[currentVideoIndex].highlights &&
									searchDetail?.videos[currentVideoIndex].highlights.length >
										0 &&
									typeof currentVideoIndex === 'number' ? (
										<TrMapLeaflet
											marks={searchDetail?.videos[
												currentVideoIndex
											].highlights.map((highlight: any) => ({
												latitude:
													highlight &&
													highlight?.location &&
													isNaN(highlight?.location)
														? parseFloat(highlight?.location?.lat)
														: 0,
												longitude:
													highlight &&
													highlight?.location &&
													isNaN(highlight?.location)
														? parseFloat(highlight?.location?.lng)
														: 0,
												title: highlight?.title,
												previewImage: highlight?.image,
											}))}
										/>
									) : (
										''
									)}
								</Box>
							</Skeleton>
						</Flex>
						<Box
							display={{ xs: 'none', md: 'flex' }}
							paddingBottom={{ base: '30px', md: 'inherit' }}
							height={{ 'base': '300px', '2xl': '420px' }}>
							<Skeleton height={'300px'} isLoaded={!searchDetailLoading}>
								{!searchDetailLoading && (
									<TopHighlights onOpenDetailsModal={onOpenDetailsModal} />
								)}
							</Skeleton>
						</Box>
						<Link
							//@ts-ignore
							as={Box}
							to='section2'
							spy={true}
							smooth={true}
							offset={-10}
							duration={500}
							style={{ cursor: 'pointer', color: '#fff' }}>
							{/* <Box
								mt={{ 'base': pixels['30pixels'], '2xl': pixels['40pixels'] }}
								display={'flex'}
								alignItems={'center'}
								justifyContent={'center !important'}
								position={'fixed'}
								bottom={{ 'base': '20px', 'lg': '40px', '2xl': '50px' }}
								right={'35%'}>
								<ScrollNextButton />
							</Box> */}
						</Link>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};

export default SearchPageSection;
