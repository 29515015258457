import {
    VStack,
    Flex,
    Box,
    HStack,
    IconButton,
    Button,
	Text
} from "@chakra-ui/react";
import { pixels } from "../theme";
import TrOutlineButton from "./TrOutlineButton";
import { useState , useContext } from "react";
import SavedPlaces from "./SavedPlaces";
import SavedVideos from "./SavedVideos";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const BucketCardDetail = () => {
    const [activeTab, setActiveTab] = useState("2");
    const navigate = useNavigate();
    const context = useContext(AppContext);
	const { bucketListCard } = context;
    const handleBackButton = () => {
        navigate(-1);
    };
    const showSavedPlaces = () => {
        setActiveTab("2");
    };
    const showSavedVideos = () => {
        setActiveTab("3");
    };
    return (
        <>
            <Flex
                overflow={"auto"}
                flexDir={"column"}
                bg="rgba(0, 0, 0, 1)"
                pt={{
                    base: 0,
                    lg: pixels["50pixels"],
                    "2xl": pixels["65pixels"],
                }}
                pb={pixels["150pixels"]}
                w="100%"
                pl={"15.5px"}
                pr={"15.5px"}
            >
                <Flex
                    justify="space-between"
                    align="center"
                    maxH="68px"
                    w={"100%"}
                    py="16px"
                    visibility={["visible", "visible", "hidden"]}
                    mt={1}
                >
                    <IconButton
                        aria-label="Back"
                        icon={
                            <img src="/icons/Back_Icon_White.svg" alt="back" />
                        }
                        colorScheme="gray"
                        size="lg"
                        variant={"link"}
                        onClick={handleBackButton}
                    />

                    <Button bg="inherit" variant="link">
                        <img src={"/icons/share3.svg"} alt="share Icon" />
                    </Button>
                </Flex>
				<Flex w={'fit-content'} position={'relative'} direction={'column'} pb={5}>
					<Text
						fontFamily='Poppins'
						fontSize='53px'
						fontWeight='600'
						lineHeight='59.23px'
						letterSpacing='-2.054992914199829px'
						textAlign='left'
						bgGradient={
							'linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)'
						}
						bgClip={'text'}>
						{bucketListCard[1]}
					</Text>
					<Text
						fontFamily='Poppins'
						fontSize='13px'
						fontWeight='500'
						lineHeight='16.95px'
						letterSpacing='0.30000001192092896px'
						textAlign='left'
						color={'#9D9D9D'}>
						{bucketListCard[0]}
					</Text>
				</Flex>			
                <Flex
                    flexDir={"column"}
                    mt={{ base: 0, lg: "90px", "2xl": "120px" }}
                    maxW={{
                        base: "100%",
                        md: pixels["700pixels"],
                        lg: pixels["894pixels"],
                        "2xl": pixels["1100pixels"],
                    }}
                    gap={pixels["30pixels"]}
                    mx="auto"
                    w={"100%"}
                >
                    <Box w={"100%"}>
                        <VStack minH={"100vh"} width={"100%"} >
                            <HStack w={"100%"} gap={4} mb={5}>
                                {/* <TrOutlineButton
								fontSize={'20px'}
								borderRadius={'30px'}
								border={
									activeTab === '1' ? '1px solid white' : ' 1px solid grey'
								}
								onClick={showOverview}>
								Overview
							</TrOutlineButton> */}
                                <TrOutlineButton
                                    height={"33px"}
                                    width={"127px"}
                                    fontSize={"16px"}
                                    borderRadius={"33px"}
                                    border={
                                        activeTab === "2"
                                            ? "1px solid white"
                                            : " 1px solid grey"
                                    }
                                    onClick={showSavedPlaces}
                                >
                                    Saved places
                                </TrOutlineButton>
                                <TrOutlineButton
                                    height={"33px"}
                                    width={"125px"}
                                    fontSize={"16px"}
                                    borderRadius={"33px"}
                                    border={
                                        activeTab === "3"
                                            ? "1px solid white"
                                            : " 1px solid grey"
                                    }
                                    onClick={showSavedVideos}
                                >
                                    Saved videos
                                </TrOutlineButton>
                            </HStack>

                            {activeTab === "2" && <SavedPlaces />}
                            {activeTab === "3" && <SavedVideos />}
                        </VStack>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};

export default BucketCardDetail;
